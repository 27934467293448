<template>
  <div>

    

    <div class="main " style="background:#fff;">


      <div class="section">
        <div class="container">

          
          <div>
            <b-form class="form" @submit.stop.prevent="submit">
              <div
                id="kt_subheader"
                class="subheader py-2 py-lg-4 mb-5 gutter-b subheader-solid"
              >
                <div
                  class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
                >
                  <!--begin::Info-->
                  <div class="d-flex align-items-center flex-wrap mr-2">
                    <!--begin::Page Title-->
                    <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5"></h5>
                    <!--end::Page Title-->
                    <!--begin::Action-->
                    <div
                      class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"
                    ></div>

                    <span class="text-muted font-weight-bold mr-4"> </span>
                    <div
                      class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"
                    ></div>

                    <!--end::Action-->
                  </div>

                  <div class="d-flex align-items-center">
                    <button type="submit" class="btn btn-success mr-2">
                      Save & Continue Edit
                    </button>
                  </div>
                  <!--end::Info-->
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="card-title">Build your partner application</h3>
                    </div>

                    <div class="container">
                      <p>
                        You can add additional questions to your partner
                        application to customize things.
                      </p>
                      <v-container fluid>
                                              <p>Add additional questions to Application:</p>

                        <v-switch
                          v-model="hasQuestion"
                          large
                          name="check-button"
                          switch
                        >
                        </v-switch>
                      </v-container>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="card">
                    <div class="card-header">
                      <h5 class="card-title">Connect with Partners</h5>
                    </div>

                    <div class="container">
                      <p>
                       These are the questions that will supplement the standard buyer application that a prospective customer will send to you. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="hasQuestion" class="card mt-7">
                <div class="card-header">
                  <h5 class="card-title">Build your Partner Application</h5>
                </div>

                <div class="card-body">
                  <div>
                    <b-card bg-variant="light">
                      <b-form>
                        <b-form-group
                          v-for="(question, index) in updatedQuestions"
                          :key="index"
                          :label="`Question ${index + 1}:`"
                          :description="
                            index === 0
                              ? 'What question do you want to ask future partner. Example: What other brands do you carry?'
                              : null
                          "
                        >
                          <v-container class="grey lighten-5">
                            <v-row no-gutters>
                              <v-col cols="12" sm="6" md="10">
                                <b-form-input
                                  ref="question1"
                                  v-model="updatedQuestions[index].text"
                                  required
                                  placeholder="Enter question"
                                ></b-form-input>
                              </v-col>
                              <v-col cols="6" md="2">
                                <v-btn
                                  class="mx-2"
                                  fab
                                  dark
                                  small
                                  color="red"
                                  @click="
                                    deleteQuestion(
                                      updatedQuestions[index],
                                      index
                                    )
                                  "
                                >
                                  <v-icon dark>
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                        </b-form-group>
                      </b-form>
                    </b-card>
                  </div>
                  <div class="my-2">
                    <v-btn color="primary" fab small dark @click="addQuestion">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    Add question (limit 10)
                  </div>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import GetCompanyQuestions from "@/graphql/queries/GetCompanyQuestions.gql";
import UpdateQuestions from "@/graphql/mutations/UpdateQuestions.gql";

export default {
  data() {
    return {
      updatedQuestions: [],
      hasQuestion: true,
      deleteQuestions: [],
    };
  },
  apollo: {
    questions: {
      query: GetCompanyQuestions,
      variables() {
        return {
          companyId: this.$store.state.auth.user.company_relation,
        };
      },
    },
  },
  watch: {
    questions(newValue) {
      this.updatedQuestions = newValue;
    },
  },
  mounted() {
    this.getCompanyInfo({
      id: this.$store.state.auth.user.company_relation,
    }).then((res) => {
      if (res.data.hasQuestion !== null) {
        this.hasQuestion = res.data.has_question;
      }
    });
  },
  methods: {
    ...mapActions([
      "updateCompanyInfo",
      "getQuestionInfo",
      "getCompanyInfo",
      "updateQuestion",
    ]),
    addQuestion() {
      this.updatedQuestions.push({ text: "", id: null });
    },
    deleteQuestion(question, index) {
      this.deleteQuestions.push({ where: { id: question.id } });
      this.updatedQuestions.splice(index, 1);
    },
    async submitQuestions() {
      const existingQuestions = this.updatedQuestions
        .filter((q) => q.id !== null)
        .map((q) => ({ data: { text: q.text }, where: { id: q.id } }));
      const newQuestions = this.updatedQuestions
        .filter((q) => q.id === null)
        .map((q) => ({
          data: {
            text: q.text,
            company: this.$store.state.auth.user.company_relation,
          },
        }));

      const deleteQuestions = this.deleteQuestions;
      await this.$apollo.mutate({
        mutation: UpdateQuestions,
        variables: {
          existingQuestions,
          newQuestions,
          deleteQuestions,
        },
      });
    },

    async submit(e) {
      e.preventDefault();
      if (this.hasQuestion == true) {
        if (
          this.updatedQuestions.length > 0 &&
          this.updatedQuestions[0].text == ""
        ) {
          // this.$refs.question1.focus();
          this.$bvToast.toast("Empty question!", {
            title: "Error",
            variant: "danger",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
          });
          return;
        } else {
          try {
            await this.submitQuestions();
            await this.updateCompanyInfo({
              id: this.$store.state.auth.user.company_relation,
              has_question: this.hasQuestion,
            });
            Swal.fire({
              title: "",
              text: "The information has been successfully updated!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
            await this.$apollo.queries.questions.refetch();
          } catch (error) {
            this.$bvToast.toast("An Error Occured!", {
              title: "Error",
              variant: "danger",
              toaster: "b-toaster-bottom-right",
              solid: true,
              appendToast: true,
            });
          }
        }
      } else {
        try {
          await this.updateCompanyInfo({
            id: this.$store.state.auth.user.company_relation,
            has_question: this.hasQuestion,
          });

          Swal.fire({
            title: "",
            text: "The information has been successfully updated!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
          });
        } catch (error) {
          this.$bvToast.toast("An Error Occured!", {
            title: "Error",
            variant: "danger",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
          });
        }
      }
    },
  },
};
</script>
